import request from '@/utils/request';
import { IQuestionnaireData, IReponseData } from '@/api/types';

export const getQuestionnaire = (params: any) =>
    request({
        url: '/questionnaires',
        method: 'get',
        params
    })

export const getQuestionnaireData = (questionnaireId: string, params: any) =>
    request({
        url: '/questionnaires/' + questionnaireId + '/responses',
        method: 'get',
        params
    })

export const addQuestionnaire = (data: IQuestionnaireData) =>
    request({
        url: '/questionnaires',
        method: 'post',
        data
    })

export const updateQuestionnaire = (questionnaireId: string, data: IReponseData[]) =>
    request({
        url: '/questionnaires/' + questionnaireId + '/responses',
        method: 'put',
        data
    })